import { ShCountry } from '@shoootin/config';
import { ShCountries } from '@shoootin/config';
import { Optional } from 'utility-types';

/**
 * Intro Cost    120,00 €
 * Cost per hour    60,00 €
 * Min time-on-site    2
 * Ideal Pax Per hour    10
 * Studio Cost for clients        199,00 €
 * Price per final/delivered photo        20,00 €
 */

const introCostPerCountry: Partial<Record<ShCountry, number>> = {
  US: 20000,
  FR: 12000,
};

const costPerHourPerCountry: Partial<Record<ShCountry, number>> = {
  US: 12000,
  FR: 6000,
};

const defaultIntroCost = 20000;
const defaultCostPerHour = 12000;

// const introCost = 12000;
// const costPerHour = 6000;
export const minTimeOnSite = 120;
const installationMinutes = 60;
const idealMinutesPerPerson = 6;
const studioCostForClients = 19900;
const pricePerFinalDeliveredPhoto = 2000;

const roundUpToNearest30Minutes = (minutes: number) => {
  const remainingMinutes = minutes % 30;
  if (remainingMinutes > 0) {
    return minutes + (30 - remainingMinutes);
  }
  return minutes;
};

export const minutesToHour = (min: number): string => {
  const hours = Math.floor(min / 60);
  const remainingMinutes = min % 60;

  return `${hours}h${remainingMinutes === 0 ? '' : remainingMinutes}`;
};

export const getRecommendedDuration = (nbOfPeople: number) => {
  const recommendedDuration =
    installationMinutes + nbOfPeople * idealMinutesPerPerson;

  const finalRecommendation = Math.max(minTimeOnSite, recommendedDuration);

  return roundUpToNearest30Minutes(finalRecommendation);
};

export const getProposedMinutes = (recommendedDuration: number) => {
  const minProposedMinutes = Math.max(minTimeOnSite, recommendedDuration - 60);
  return [
    minProposedMinutes,
    minProposedMinutes + 30,
    minProposedMinutes + 60,
    minProposedMinutes + 90,
    minProposedMinutes + 120,
  ];
};

export const getPortraitPrice = (
  country: ShCountry,
  nbOfPeople: number,
  nbOfPortraitPerPeople: number,
  minutes: number,
) => {
  return (
    (introCostPerCountry[country] || defaultIntroCost) +
    studioCostForClients +
    ((costPerHourPerCountry[country] || defaultCostPerHour) * minutes) / 60 +
    nbOfPeople * nbOfPortraitPerPeople * pricePerFinalDeliveredPhoto
  );
};
