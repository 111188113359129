/* eslint-disable camelcase */
import { OrderStep } from './context/orderPageContextSteps';
import { defineMessages } from 'react-intl';
import { ShIntlMessageDefinition } from '@shoootin/translations';

const prefix = 'front_order';
const branding_prefix = `${prefix}_branding`;

const step_prefix = `${prefix}_step`;
const step_prefix_order = `${step_prefix}_order`;
const step_prefix_book = `${step_prefix}_book`;
const step_prefix_login = `${step_prefix}_login`;
const step_prefix_payment = `${step_prefix}_payment`;
const step_prefix_confirmation = `${step_prefix}_confirmation`;
const step_prefix_checkout = `${step_prefix}_checkout`;

export const OrderStepLabels: {
  [key in OrderStep]: ShIntlMessageDefinition;
} = defineMessages({
  order: {
    id: `${step_prefix}_order`,
    defaultMessage: 'Order',
  },
  book: {
    id: `${step_prefix}_book`,
    defaultMessage: 'Book',
  },
  login: {
    id: `${step_prefix}_login`,
    defaultMessage: 'Login',
  },
  payment: {
    id: `${step_prefix}_payment`,
    defaultMessage: 'Payment',
  },
  confirmation: {
    id: `${step_prefix}_confirmation`,
    defaultMessage: 'Confirmation',
  },
});

export const OrderStepOrder = defineMessages({
  offerForbiddenForUser: {
    id: `${step_prefix_book}_offerForbiddenForUser`,
    defaultMessage:
      "L'offre que vous avez sélectionnée n'est pas disponible pour votre compte. Sélectionnez à nouveau vos offres.",
  },
  intro: {
    id: `${step_prefix_order}_intro`,
    defaultMessage:
      'Pour commander des photos, une vidéo ou même une visite virtuelle Matterport, c’est par ici et en quelques clics.',
  },
  shootingType: {
    id: `${step_prefix_order}_shootingType`,
    defaultMessage: 'Type de shooting',
  },
  classicOffers: {
    id: `${step_prefix_order}_classicOffers`,
    defaultMessage: 'Nos prestations classiques',
  },
  droneOffers: {
    id: `${step_prefix_order}_droneOffers`,
    defaultMessage: 'Nos prestations drones',
  },
  customOffers: {
    id: `${step_prefix_order}_customOffers`,
    defaultMessage: 'Vos prestations sur mesure',
  },
  addOns: {
    id: `${step_prefix_order}_addOns`,
    defaultMessage: 'Add-ons',
  },
  help: {
    id: `${step_prefix_order}_help`,
    defaultMessage: 'Besoin d’aide pour choisir',
  },

  contactInfos: {
    id: `${step_prefix_order}_contactInfos`,
    defaultMessage: 'Où vous rencontrer ?',
  },
  userInfos: {
    id: `${step_prefix_order}_userInfos`,
    defaultMessage: 'Order for a colleague',
  },

  scanSurfaceLegend: {
    id: `${step_prefix_order}_scanSurfaceLegend`,
    defaultMessage:
      'Il est très important de nous indiquer la surface à scanner afin de prévoir assez de temps pour la mission.',
  },
  scanSurfaceError: {
    id: `${step_prefix_order}_scanSurfaceError`,
    defaultMessage: 'La surface choisie est trop importante.',
  },

  scanExtraModelLegend: {
    id: `${step_prefix_order}_scanExtraModelLegend`,
    defaultMessage:
      'Si vous avez 3 espaces séparés à scanner, il faut choisir 2 modèles supplémentaires',
  },
  scanExtraModelError: {
    id: `${step_prefix_order}_scanExtraModelError`,
    defaultMessage: 'Le nombre de modèles supplémentaires est trop important.',
  },
  cartTitle: {
    id: `${step_prefix_order}_cartTitle`,
    defaultMessage: 'Votre panier',
  },
  cartDiscount: {
    id: `${step_prefix_order}_cartDiscount`,
    defaultMessage: 'Réduction incluse',
  },
  cartExtraCharge: {
    id: `${step_prefix_order}_cartExtraCharge`,
    defaultMessage: 'Supplément',
  },

  droneFormMapReset: {
    id: `${step_prefix_order}_droneFormMapReset`,
    defaultMessage: 'Reset',
  },
  droneFormDatesDescription: {
    id: `${step_prefix_order}_droneFormDatesDescription`,
    defaultMessage: 'Choisissez plusieurs dates de disponibilités',
  },

  droneFormCGVTitle: {
    id: `${step_prefix_order}_droneFormCGVTitle`,
    defaultMessage: 'Conditions générales',
  },

  droneFormCGVContent: {
    id: `${step_prefix_order}_droneFormCGVContent`,
    defaultMessage:
      'Seules les missions comportant les informations / documents de ce  questionnaire seront acceptées pour toute commande de reportage drone.',
  },
  droneFormCGVCheckbox: {
    id: `${step_prefix_order}_droneFormCGVCheckbox`,
    defaultMessage: "J'ai lu et j'accepte les conditions générales de vente",
  },
  droneFormUserTitle: {
    id: `${step_prefix_order}_droneFormUserTitle`,
    defaultMessage: 'Vos informations',
  },
  droneFormFileAutorisationDescription: {
    id: `${step_prefix_order}_droneFormFileAutorisationDescription`,
    defaultMessage:
      'Nous avons besoin pour les préfectures que le propriétaire remplisse et signe le document suivant :',
  },
  droneFormFileMissionDescription: {
    id: `${step_prefix_order}_droneFormFileMissionDescription`,
    defaultMessage:
      'Nous avons besoin pour les préfectures que le propriétaire remplisse et signe le document suivant : https://files.shoootin.com/articles/intercom_fr_drone_lettre_mission.pdf',
  },
  droneFormFileExtraInfosDescription: {
    id: `${step_prefix_order}_droneFormFileExtraInfosDescription`,
    defaultMessage:
      "Vous pouvez charger d'autres documents pertinents si nécessaire",
  },
  droneFormSuccessTitle: {
    id: `${step_prefix_order}_droneFormSuccessTitle`,
    defaultMessage: 'Demande envoyée',
  },
  droneFormSuccessDescription: {
    id: `${step_prefix_order}_droneFormSuccessDescription`,
    defaultMessage:
      'Nos équipes ont bien reçu les instructions et vont revenir vers vous dans un délai de 48 heures maximum pour confirmer la seance.',
  },
});
//
// export const OrderStepBook = defineMessages({
//   intro: {
//     id: `${step_prefix_book}_intro`,
//     defaultMessage:
//       'Sélectionnez une date et une heure pour votre rendez-vous.',
//   },
//   unavailable: {
//     id: `${step_prefix_book}_unavailable`,
//     defaultMessage:
//       "La disponibilité que vous aviez initialement choisie ({initialSlot}) n'est plus disponible, nous vous invitons à choisir un autre créneau.",
//   },
//   availabilityOf: {
//     id: `${step_prefix_book}_availabilityOf`,
//     defaultMessage: 'Disponibilités du {day} :',
//   },
//   currentTimezone: {
//     id: `${step_prefix_book}_currentTimezone`,
//     defaultMessage:
//       'Tous les créneaux sont affichés pour le fuseau horaire {timeZone}.',
//   },
//   ifNoAvailability: {
//     id: `${step_prefix_book}_ifNoAvailability`,
//     defaultMessage:
//       'S’il n’y a aucun créneau disponible pour vos dates, notre équipe va vous trouvez une solution, contactez-nous pour que l’on puisse vous aider.',
//   },
//   noAvailabilityBefore: {
//     id: `${step_prefix_book}_noAvailabilityBefore`,
//     defaultMessage:
//       "Nous n'avons plus de disponibilités avant le {selectedDay}.",
//   },
//   noAvailabilityMustBeDone: {
//     id: `${step_prefix_book}_noAvailabilityMustBeDone`,
//     defaultMessage:
//       'Votre reportage doit être fait le {selectedDay} ? {clickHere} et notre équipe va trouver une solution.',
//   },
//   clickHere: {
//     id: `${step_prefix_book}_clickHere`,
//     defaultMessage: 'Cliquez ici',
//   },
//   noAvailabilityAtAll: {
//     id: `${step_prefix_book}_noAvailabilityAtAll`,
//     defaultMessage:
//       "Notre équipe va trouver une solution et revenir vers vous rapidement. Pour cela, merci d'envoyer votre demande de reportage en cliquant sur le bouton ci-desous",
//   },
//
//   noAvailabilityAtAllTitle: {
//     id: `${step_prefix_book}_noAvailabilityAtAllTitle`,
//     defaultMessage:
//         "Pas de disponibilités ",
//   },
// });

export const OrderStepLogin = defineMessages({
  // Signup
  signup_title: {
    id: `${step_prefix_login}_signup_title`,
    defaultMessage: 'Inscrivez-vous',
  },
  signup_subtitle_alreadyAccount: {
    id: `${step_prefix_login}_signup_subtitle_alreadyAccount`,
    defaultMessage: 'Vous avez déjà un compte ?',
  },
  // Login
  login_title: {
    id: `${step_prefix_login}_login_title`,
    defaultMessage: 'Identifiez-vous',
  },
  login_subtitle: {
    id: `${step_prefix_login}_login_subtitle`,
    defaultMessage: "Vous n'avez pas de compte ?",
  },
  login_createAccount: {
    id: `${step_prefix_login}_login_createAccount`,
    defaultMessage: 'Créer mon compte',
  },
  // Logged
  logged_title: {
    id: `${step_prefix_login}_logged_title`,
    defaultMessage: 'Vous êtes connecté',
  },
  logged_subtitle: {
    id: `${step_prefix_login}_logged_subtitle`,
    defaultMessage: 'Vous êtes deja connecté',
  },
  logged_message: {
    id: `${step_prefix_login}_logged_message`,
    defaultMessage:
      "Bonjour {firstName}, vous êtes connecté avec l'adresse {email}. Souhaitez-vous commander avec un autre compte ?",
  },
  logged_changeAccount: {
    id: `${step_prefix_login}_logged_changeAccount`,
    defaultMessage: 'Changer de compte',
  },
  logged_keepAccount: {
    id: `${step_prefix_login}_logged_keepAccount`,
    defaultMessage: 'Non, je souhaite payer',
  },
});

export const OrderStepPayment = defineMessages({
  intro: {
    id: `${step_prefix_payment}_intro`,
    defaultMessage:
      'Confirmez votre shooting en choisissant la méthode de paiement adaptée.',
  },

  showDetails: {
    id: `${step_prefix_payment}_showDetails`,
    defaultMessage: 'Voir le détail',
  },
  hideDetails: {
    id: `${step_prefix_payment}_hideDetails`,
    defaultMessage: 'Cacher le détail',
  },
  rdv: {
    id: `${step_prefix_payment}_rdv`,
    defaultMessage: 'Rendez-vous',
  },
  useCreditToReduce: {
    id: `${step_prefix_payment}_useCreditToReduce`,
    defaultMessage: 'Utiliser {credit} de votre crédit.',
  },
  cgv: {
    id: `${step_prefix_payment}_cgv`,
    defaultMessage:
      'J’ai lu et j’accepte les Conditions Générales d’Utilisation.',
  },

  // paymentMethodTitle: {
  //   id: `${step_prefix_payment}_paymentMethodTitle`,
  //   defaultMessage: 'Moyen de paiement',
  // },

  // paymentMethodIntro: {
  //   id: `${step_prefix_payment}_paymentMethodIntro`,
  //   defaultMessage: 'Choisissez votre moyen de paiement préféré.',
  // },
  paymentMethodOrderInstructions: {
    id: `${step_prefix_payment}_paymentMethodOrderInstructions`,
    defaultMessage:
      'Votre commande sera soumise à la validation des administrateurs de votre compte une fois validée.',
  },
  // paymentMethodSavedCreditCardTitle: {
  //   id: `${step_prefix_payment}_paymentMethodSavedCreditCardTitle`,
  //   defaultMessage: 'Choisir une carte',
  // },
  // paymentMethodCreditDescription: {
  //   id: `${step_prefix_payment}_paymentMethodCreditDescription`,
  //   defaultMessage: 'Il vous reste {credit} de crédit sur votre compte.',
  // },
  // paymentMethodUnlimitedDescription: {
  //   id: `${step_prefix_payment}_paymentMethodUnlimitedDescription`,
  //   defaultMessage:
  //     "Vous avez {current} d'encours de consommation à la carte avec une limite de {limit}.",
  // },
  // paymentMethodYourCreditCard: {
  //   id: `${step_prefix_payment}_paymentMethodYourCreditCard`,
  //   defaultMessage: 'Votre carte de crédit',
  // },
  // paymentMethodYourCreditCardExpiration: {
  //   id: `${step_prefix_payment}_paymentMethodYourCreditCardExpiration`,
  //   defaultMessage: 'Expiration',
  // },
  // paymentMethodYourCreditCardCVC: {
  //   id: `${step_prefix_payment}_paymentMethodYourCreditCardCVC`,
  //   defaultMessage: 'CVC',
  // },
  // saveCreditCard: {
  //   id: `${step_prefix_payment}_saveCreditCard`,
  //   defaultMessage: 'Enregistrer votre carte',
  // },
  // paymentMethodStripeInfos: {
  //   id: `${step_prefix_payment}_paymentMethodStripeInfos`,
  //   defaultMessage:
  //     'Besoin d’en savoir plus sur la méthode de paiement Stripe ?',
  // },
});

export const OrderStepConfirmation = defineMessages({
  title: {
    id: `${step_prefix_confirmation}_title`,
    defaultMessage: 'Merci !',
  },
  introConfirmed: {
    id: `${step_prefix_confirmation}_introConfirmed`,
    defaultMessage:
      'Votre commande a bien été prise en compte.{br}Un mail de confirmation va vous être envoyé.',
  },
  introOrdered: {
    id: `${step_prefix_confirmation}_introOrdered`,
    defaultMessage:
      "Votre demande a bien été prise en compte.{br}Un mail de validation va vous être envoyé ainsi qu'aux administrateurs de votre compte afin de finaliser la commande.",
  },
});

export const OrderCheckout = defineMessages({
  loggedBanner: {
    id: `${step_prefix_checkout}_loggedBanner`,
    defaultMessage:
      "Bonjour {firstName}, vous êtes connecté avec l'adresse {email}. Souhaitez-vous commander avec un autre compte ?",
  },
  loggedChangeAccount: {
    id: `${step_prefix_checkout}_loggedChangeAccount`,
    defaultMessage: 'Changer de compte',
  },
});

export const OrderPortrait = defineMessages({
  title: {
    id: `${step_prefix_book}_title`,
    defaultMessage: 'Your portrait offer',
  },
  portraitDate: {
    id: `${step_prefix_book}_portraitDate`,
    defaultMessage: 'Your desired date',
  },
  portraitGuidelineUpload: {
    id: `${step_prefix_book}_portraitGuidelineUpload`,
    defaultMessage: 'Upload your guideline',
  },
  portraitInformation: {
    id: `${step_prefix_book}_portraitInformation`,
    defaultMessage: 'Your informations',
  },
  portraitSuccessTitle: {
    id: `${step_prefix_book}_portraitSuccessTitle`,
    defaultMessage: 'Your request has been sent',
  },
  portraitSuccessDescription: {
    id: `${step_prefix_book}_portraitSuccessDescription`,
    defaultMessage:
      'Our teams have received your instructions and will get back to you within 48 hours maximum to confirm the session.',
  },
  portraitCartNbOfPortraits: {
    id: `${step_prefix_book}_portraitCartNbOfPortraits`,
    defaultMessage: 'Number of portraits',
  },
  portraitCartNbPerPerson: {
    id: `${step_prefix_book}_portraitCartNbPerPerson`,
    defaultMessage: 'Portraits per person',
  },
});
